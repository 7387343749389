import React, {useEffect, useState} from 'react';
import {
    EditOutlined,
    EyeOutlined,
    PlusCircleOutlined,
} from '@ant-design/icons';
import {Button, Card, Space, Table, Tooltip} from 'antd';
import {useNavigate} from 'react-router-dom';
import {FaUserCog} from 'react-icons/fa';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import {addMenu, disableRefetch, setMenuData} from '../../redux/slices/menu';
import {useTranslation} from 'react-i18next';
import formatSortType from '../../helpers/formatSortType';
import useDidUpdate from '../../helpers/useDidUpdate';
import UserShowModal from './userShowModal';
import UserRoleModal from './userRoleModal';
import {fetchClients} from '../../redux/slices/client';
import SearchInput from '../../components/search-input';
import moment from 'moment';

const User = () => {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {activeMenu} = useSelector((state) => state.menu, shallowEqual);
    const {clients, loading, meta, params} = useSelector(
        (state) => state.client,
        shallowEqual
    );


    // vendor list action
    const [vendors, setVendors] = useState('')
    // call  vendor list get api
    useEffect(() => {


        // fetch data
        const dataFetch = async () => {
            const data = await (
                await fetch(
                    "https://api.goatmoves.com/api/v1/dashboard/admin/interested-sellers/paginate", {
                        method: 'GET',
                        headers: {
                            'authorization': `Bearer ${localStorage.getItem('token')}`,
                        }
                    }
                )
            ).json();

            // set state when the data received
            setVendors(data)
        };

        dataFetch()


    }, [])


    useEffect(() => {
        console.log('vendor',vendors)
    }, [vendors])

    useEffect(() => {
        console.log('Meta:', meta);
        console.log('Params:', params);
    }, [meta, params]);

const vendorBody = vendors?.data
    // old cole

    const [uuid, setUuid] = useState(null);
    const [userRole, setUserRole] = useState(null);

    const goToEdit = (row) => {
        dispatch(
            addMenu({
                url: `user/${row.uuid}`,
                id: 'user_edit',
                name: t('edit.user'),
            })
        );
        navigate(`/user/${row.uuid}`);
    };

    const columns = [
        {
            title: t('id'),
            dataIndex: 'id',
            key: 'id',
            sorter: true,
        },
        {
            title: t('Full Name'),
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: t('Business Name'),
            dataIndex: 'business_name',
            key: 'business_name',
        },
        {
            title: t('Business Address'),
            dataIndex: 'business_address',
            key: 'business_address',
        },
        {
            title: t('EIN'),
            dataIndex: 'ein',
            key: 'ein',
        },
        {
            title: t('Email'),
            dataIndex: 'email',
            key: 'email',
            render: (email) => (
                <div>{process.env.REACT_APP_IS_DEMO === 'true' ? '' : email}</div>
            ),
        },
        {
            title: t('Phone'),
            dataIndex: 'phone',
            key: 'phone',
            render: (phone) => phone,
        },
        {
            title: t('URL'),
            dataIndex: 'url',
            key: 'url',
        },
        {
            title: t('registered_at'),
            dataIndex: 'registered_at',
            key: 'registered_at',
            render: (data, row) => moment(data).format('MMM Do YY'),
        },
        /*{
            title: t('options'),
            dataIndex: 'options',
            key: 'options',
            render: (data, row) => {
                return (
                    <Space>
                        <Button icon={<EyeOutlined/>} onClick={() => setUuid(row.uuid)}/>
                        <Button
                            type='primary'
                            icon={<EditOutlined/>}
                            onClick={() => goToEdit(row)}
                        />
                        <Tooltip title={t('change.user.role')}>
                            <Button onClick={() => setUserRole(row)} icon={<FaUserCog/>}/>
                        </Tooltip>
                    </Space>
                );
            },
        },*/
    ];

    function onChangePagination(pagination, filters, sorter) {
        const {pageSize: perPage, current: page} = pagination;
        const {field: column, order} = sorter;
        const sort = formatSortType(order);
        dispatch(
            setMenuData({activeMenu, data: {perPage, page, column, sort}})
        );
    }

    useEffect(() => {
        if (activeMenu.refetch) {
            dispatch(fetchClients());
            dispatch(disableRefetch(activeMenu));
        }
    }, [activeMenu.refetch]);

    useDidUpdate(() => {
        const data = activeMenu.data;
        const paramsData = {
            sort: data?.sort,
            column: data?.column,
            perPage: data?.perPage,
            page: data?.page,
            search: data?.search,
        };
        dispatch(fetchClients(paramsData));
    }, [activeMenu.data]);

    /*const goToAddClient = () => {
        dispatch(
            addMenu({
                id: 'user-add',
                url: 'user/add',
                name: t('add.client'),
            })
        );
        navigate('/user/add');
    };*/

    const handleFilter = (item, name) => {
        const data = activeMenu.data;
        dispatch(
            setMenuData({
                activeMenu,
                data: {...data, [name]: item},
            })
        );
    };

    const data = vendorBody?.length > 0 ? vendorBody.map((i, index) => ({
        id: index + 1,
        name: i?.name,
        business_name: i?.business_name,
        business_address: i?.business_address,
        ein: i?.ein,
        email: i?.email,
        phone: i?.phone,
        url: i?.website,
    })) : [];


    return (
        <>
            <Card
                title={t('Vendor')}
                /*extra={
                    <Space>
                        <SearchInput
                            placeholder={t('search')}
                            handleChange={(search) => handleFilter(search, 'search')}
                        />
                        <Button
                            type='primary'
                            icon={<PlusCircleOutlined/>}
                            onClick={goToAddClient}
                        >
                            {t('add.client')}
                        </Button>
                    </Space>
                }*/
            >
                <Table
                    columns={columns}
                    dataSource={data}
                    loading={loading}
                    // pagination={{
                    //     pageSize: params.perPage,
                    //     current: params.page,
                    //     total: meta.total,
                    //     defaultCurrent: params.page,
                    // }}
                    rowKey={(record) => record.id}
                    onChange={onChangePagination}
                    total={10}
                />


                {uuid && <UserShowModal uuid={uuid} handleCancel={() => setUuid(null)}/>}
                {userRole && (
                    <UserRoleModal data={userRole} handleCancel={() => setUserRole(null)}/>
                )}

            </Card>

        </>


    );
};

export default User;